
    export const addLeadingZeros = function(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    export const joinStringArray = function(strArray) {
        var s = "";
        for(var i in strArray){
            var str = strArray[i];
            s += ",'"+str+"'"
        }
        return s.substring(1);
    }

    export const buildXMLFilter = function(props, properties){
        var xml = '<Filter>'  
        if((props&&props.exclude)||properties){
          xml += '<And>';
          if(!properties) properties = [];
          if(props.exclude) properties.push(props.exclude);
          xml += buildPropertyEqualFilterXML(properties);
        } 
        if(properties.geometry){
            var geometry = properties.geometry;
            xml += '<Intersects>'  ;      
            xml += '<PropertyName>the_geom</PropertyName>';
            var type = geometry.type==="MultiPolygon"?"Polygon":geometry.type;
            var srsId = geometry.srsId?geometry.srsId:"3857"
            xml += '<gml:'+type+' srsName="http://www.opengis.net/gml/srs/epsg.xml#'+srsId+'">';
            if(geometry.type==="Polygon"){
            xml += '<gml:exterior><gml:LinearRing><gml:posList>' ;
            xml += geometry.coordinates
            xml += '</gml:posList></gml:LinearRing></gml:exterior>';
            }else if(geometry.type==="Point"){
            xml += '<gml:pos>'+geometry.coordinates+'</gml:pos>'  ;  
            }else if(geometry.type==="MultiPolygon"){
                xml += '<gml:exterior><gml:LinearRing><gml:posList>' ;
                xml += geometry.coordinates[0]
                xml += '</gml:posList></gml:LinearRing></gml:exterior>';
            }
            //       
            xml += '</gml:'+type+'>';
            xml += '</Intersects>';
        }
        if((props&&props.exclude)||properties) xml += '</And>';
        xml += '</Filter>';
        return xml;
    }

    export const buildPropertyEqualFilterXML = function(properties){
        var xml = "";
        for(var k=0; k<properties.length; k++){
            var property = properties[k];
            if(property.values.length===1){
                if(property.isEqual){
                    xml += '<PropertyIsEqualTo>'
                }else{
                    xml += '<PropertyIsNotEqualTo>'
                }
                xml += '<PropertyName>'+property.name+'</PropertyName>'
                xml += '<Literal>'+property.values[0]+'</Literal>'
                if(property.isEqual){
                    xml += '</PropertyIsEqualTo>'
                }else{
                    xml += '</PropertyIsNotEqualTo>'
                }
            }else if(property.values.length>1){
                xml += '<PropertyIsEqualTo>'
                xml += '<Function name="in">'
                xml += '<PropertyName>'+property.name+'</PropertyName>'
                for(var i in property.values){
                    xml += ' <Literal>'+property.values[i]+'</Literal>'
                }
                xml += '</Function>'
                xml += '<Literal>'+(property.isEqual?"true":"false")+'</Literal>';
                xml += '</PropertyIsEqualTo>';
            }
            
        }
        return xml;
    }
