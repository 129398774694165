import React from 'react';
import Tool from '../Tool';
import ItemList from '../../ItemList/Widget'
import {isLayerVisible} from '../../MapUtil.js'

class Widget extends Tool {

    constructor(props){
        // console.log(1)
        super(props);
        this.getContent = this.getContent.bind(this);
        this.mapViewChanged = this.mapViewChanged.bind(this);
    }

    getTitle(){
        return "Legend";
    }

    getHeight(){
        return 530;
    }

    getIconClass(){ 
        return "fab fa-elementor";
    }

    
    mapViewChanged(){
        if(window.app.floatingPane.state && window.app.floatingPane.state.toolPane && window.app.floatingPane.state.toolPane.style.visibility !== 'hidden' && window.app.floatingPane.state.toolPane.title === this.getTitle()) {
            this.onToolIconClick();
        }
    }

    addMapEvent(){
        window.app.map.getView().on('change:resolution', this.mapViewChanged)
    }

    getContent(){
        // console.log(2)
        this.items = [];
        var resolution = window.app.map.getView().getResolution();
        for(var key in window.app.layers.opLayers){
            var layer = window.app.layers.opLayers[key];
            var hasLegend = layer.get("props")&& layer.get("props").hasLegend;
            if(hasLegend && isLayerVisible(layer, resolution)){
                var source = layer.getSource();
                var legendSrc, name, nameFirst;
                if(source.getLegendUrl){
                    legendSrc = layer.getSource().getLegendUrl(resolution);
                    nameFirst = hasLegend===2;
                    name = key;
                }
                this.items.push({name: name, url: legendSrc, nameFirst:nameFirst});
            }
        }
        return (
            <ItemList items={this.items}></ItemList>
        )
    }
}

export default Widget;  