import React from 'react';
import Tool from '../Tool';
import ExternalPane from '../../ExternalPane/Widget';

class Widget extends Tool {

    constructor(props){
        super(props);
        console.log(props);
        this.title = "FMV";
        this.vedioElemId = "fmvvideo"
        this.onMapClick = this.onMapClick.bind(this);
        if(!window.app.mapEvents) window.app.mapEvents = [];
        window.app.mapEvents.push(this);
        this.layer = window.app.layers.opLayers[this.props.config.layer];
    }

    indexFeatures(){
        var indexedfeatures= {}
        var features = this.layer.getSource().getFeatures();
        var fields = this.props.config.fields;
        features.forEach(function(feature, idx){
            var videoName = feature.get(fields.videoName);
            var seconds = feature.get(fields.seconds);
            if(!indexedfeatures[videoName]) indexedfeatures[videoName] = {};
            indexedfeatures[videoName][seconds] = feature;  
        })
        this.indexedfeatures = indexedfeatures;   
    }

    addMapEvent(){
        this.map = window.app.map;
        this.map.on('singleclick', this.onMapClick);
    }

    onMapClick(evt){
        var w = this;
        w.mapClicked = true;
        window.app.map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
            if(w.mapClicked && layer.get("props").name === w.layer.get("props").name){
                if(!w.indexedfeatures) w.indexFeatures();
                w.mapClicked = false;
                var fields = w.props.config.fields;
                var videoName = feature.get(fields.videoName);
                var dateTime = feature.get(fields.dateTime);
                var fileURL = feature.get(fields.fileURL);
                var seconds = feature.get(fields.seconds);
                if(!w.VideoPaneOpened){
                    w.VideoPaneOpened = true;
                    window.app.instance.addExternalPane(w.props.config.windowDirection, w);
                    w.initVideo();
                }
                w.currentVideoName = videoName;
                w.setTitle(videoName + " " + dateTime)
                w.setVideo(fileURL, seconds)
                window.app.highlightLayer.highlight(feature);
            }
        });
    }

    initVideo(){
        var w = this;
        this.videoPlayer = document.getElementById(this.vedioElemId);
        this.videoPlayer.ontimeupdate = function(){
            w.updateVideo();
        }
    }

    updateVideo(){
        var currentTime = this.videoPlayer.currentTime;
        var time = Math.ceil(currentTime);
        var feature = this.indexedfeatures[this.currentVideoName][time];
        if(feature){
            var dateTime = feature.get(this.props.config.fields.dateTime);
            this.setTitle(this.currentVideoName + " " + dateTime)
            window.app.highlightLayer.highlight(feature);
        }
        // console.log(time);
        // var videoPoint = 
    }

    setTitle(title){
        var titleElem = document.getElementById(this.title+"_titleElemId");
        if(titleElem) titleElem.innerHTML = title;
    }

    setVideo(url, currentTime){
        this.videoPlayer.src = url;
        this.videoPlayer.currentTime = currentTime;
        this.videoPlayer.play()
    }

    closePane(){
        // console.log(this.loaded);
        // this.videoPlayer.pause();
        // window.app.instance.removeExternalPane(this);
        // this.loaded = false
    }

    getContent(){
        var content = (
            <video id={this.vedioElemId} className="vertical-center" width="100%" controls>
                {/* <source id="fmvvideo" src="https://drive.google.com/uc?export=download&id=1r5PHik8F9DLtqGdMiLNX9esKs6eIpkLP" type="video/mp4" autoPlay={true} /> */}
            </video>
        )
        return (  
            <ExternalPane widget={this} title={this.title} content={content}></ExternalPane>
        );
    }

    render(){
        return ""
    }

}

export default Widget;  