import React, { Component } from 'react';
// Required to use React portals
import Draggable from 'react-draggable';
import './style.css';
import logo from './close.png';

class FloatingPane extends Component {

  constructor(props){
    super(props);
    this.property = {};
    for(var i in this.props.names){
      var name = this.props.names[i];
        this.property[name] = {
        style: {
          visibility:'hidden', 
          height: window.innerHeight-120,
          width:300, 
          top:  0, 
          right: 0,
        },
        title:name
      };
    }
    window.app.floatingPane = this;
  }

  close(name){
    this.setProperties(name, {
      style:{visibility:"hidden"}
    })
  }


  setProperties(name, props){
    this.property[name] = props;
    var property = {};
    property[name] = this.property[name];
    this.setState(property);
  }

  close = (name) => { 
    this.property[name].style.visibility = 'hidden'
    var property = {};
    property[name] = this.property[name];
    this.setState(property);
    if(this.property[name].onClose) this.property[name].onClose();
  }

  getHeader(name){
    var close = ()=>{
      this.close(name);
    }
    return (
      <div className="header">
          {this.property[name].moveToPreviousItem?(<i onClick={this.property[name].moveToPreviousItem} title={"Previous Item"} className="fa fa-minus headerIcon" aria-hidden="true"></i>):('')}
          {this.property[name].moveToNextItem?(<i onClick={this.property[name].moveToNextItem} title={"Next Item"} className="fa fa-plus headerIcon" aria-hidden="true"></i>):('')}
        <strong className="cursor">
          {this.property[name].title}
        </strong>
        <img src={logo} onClick={close} className="closeImg headerIcon" alt='Close'></img>
      </div>

      // <table>
      //   <tbody>
      //   <tr>
      //     {this.property[name].moveToPreviousItem?(<td><i onClick={this.property[name].moveToPreviousItem} title={"Previous Item"} className="fa fa-minus" aria-hidden="true" style={{cursor:'pointer'}}></i></td>):(<td></td>)}
      //     {this.property[name].moveToNextItem?(<td><i onClick={this.property[name].moveToNextItem} title={"Next Item"} className="fa fa-plus" aria-hidden="true" style={{cursor:'pointer'}}></i></td>):(<td></td>)}
      //     <td style={{width:"100%"}}>{this.property[name].title}</td>
      //     <td><img src={logo} onClick={close} className="closeImg" alt='Close'></img></td>
      //   </tr>
      //   </tbody>
      // </table>
    )
  }

  render () {
    // var close = ()=>{
    //   this.close("toolPane");
    // }
    return (
      <div style={{height: window.innerHeight-50}}>
        {
          this.props.names.map(name=>(
            <Draggable key={name} bounds="parent" handle="strong" onDrag={this.handleDrag}>
              <div className="box no-cursor" style={this.property[name].style}>
                  {this.getHeader(name) }
                  <div className="contentPane" style={{height:parseInt(this.property[name].style.height, 10)-39}}>
                    <div className="content">
                        {this.property[name].content }
                    </div>
                  </div>
              </div>
            </Draggable>
          ))
        }
      </div>
    );
  }
}

export default FloatingPane;