import React from 'react'
import Grid from '@material-ui/core/Grid'

import 'ol/ol.css';
import {Map, View} from 'ol';
import {OSM} from 'ol/source';
import {transform} from 'ol/proj';
import {Tile as TileLayer} from 'ol/layer';
import {defaults as defaultControls, ZoomToExtent, OverviewMap, ScaleLine} from 'ol/control';
import './style.css';
import {getFeatureByCQL, getExtent, zoomTo} from './MapUtil'
import OlLayers from './OLLayers'
import FloatingPane from './FloatingPane/Widget';

class OLMap extends React.Component {

    constructor(props){
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this)
        this.onMapExtentChange = this.onMapExtentChange.bind(this)
        this.state = {
            height: window.innerHeight-50,
            width: window.innerWidth
        }
        window.app.mapObj = this;
    }

    updateDimensions(){
        this.setState({height: window.innerHeight-50,width:window.innerWidth })
    }

    componentDidMount(){
        window.addEventListener('resize', this.updateDimensions)
        var w = this;
        this.setOrgInfo(function(){
            w.olLayers = new OlLayers();
            var viewOption = {
                zoom:10
            }
            if(window.app.config.initMapExtent)
                viewOption = {
                    center: transform(window.app.config.initMapExtent.center,"EPSG:4326","EPSG:3857"), 
                    zoom: window.app.config.initMapExtent.zoom 
                }
            window.app.map = new Map({
                target: 'map',
                layers: w.olLayers.getLayers(),
                controls: defaultControls().extend([
                    new ScaleLine(),
                    // new MousePosition(),
                    new OverviewMap({
                        // see in overviewmap-custom.html to see the custom CSS used
                        className: 'ol-overviewmap ol-custom-overviewmap',
                        layers: [
                        new TileLayer({
                            source: new OSM()
                        })
                        ],
                        collapsed: true
                    })
                ]),
                view: new View(viewOption)
            });


            window.app.map.on('moveend', w.onMapExtentChange);
            window.app.map.once('postrender', function(evt){
                var map = evt.map;
                var extent = map.getView().calculateExtent(map.getSize())
                w.addHomeControl(extent)
                if(window.app.mapEvents)
                    window.app.mapEvents.forEach(function(wi){
                        wi.addMapEvent();
                    })
            })
        });
    }

    setOrgInfo(callback){
        if(window.app.config.orgLayer){
            var layer = window.app.config.orgLayer;
            layer.svcUrl = window.app.config.wfsUrl;//serverUrl.replace("wms", "wfs");
            layer.outSRS = window.app.config.srs;
            layer.cql = layer.attributeName+"='"+window.app.user.organization+"'";
            getFeatureByCQL(layer, function(resp){
                window.app.orgBoundary = resp.features[0].geometry;
                callback();
            });
        }else{
            callback();
        }
    }

    addHomeControl(extent){
        var zoom = document.createElement('span');
        zoom.innerHTML = '<i class="fa fa-home" style="font-size:24px" alt="Home"></i>';
        window.app.map.addControl(
                new ZoomToExtent({
                        tipLabel: "Home",
                        label: zoom,
                        extent: extent
                })
        )
    }

    onMapExtentChange(){
    }

    render(){
        // console.log(this.state.height);
        const style = {
            width: this.state.width,
            height:this.state.height
        }
        return (
            <Grid container>
                <Grid item xs={12}>
                    <div id='map' style={style} >
                    </div>
                    {/* <FloatingPane names={["toolPane", "resultPane"]}></FloatingPane> */}
                </Grid>
            </Grid>
        )
    }

}
export default OLMap