import React from 'react';
import Tool from '../Tool';
import TreeView from '../../TreeView/Widget';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {isLayerVisible} from '../../MapUtil.js';
import {loadGraphicLayerFeatures} from '../../GraphicLayerUtil';

class Widget extends Tool {

    constructor(props){
        super(props);
        this.onCheckedStateChange = this.onCheckedStateChange.bind(this);
        this.onExpandedStateChanged = this.onExpandedStateChanged.bind(this);
        this.onNodeClick = this.onNodeClick.bind(this);
        this.onIconClick = this.onIconClick.bind(this);
        this.mapViewChanged = this.mapViewChanged.bind(this);
        //this.checked = ['Employee Evaluations'];
        // this.expanded = [
        //             'Documents'
        //         ];
    }

    addMapEvent(){
        this.checked = window.app.layerList["opLayers"].checked;
        this.nodes = window.app.layerList["opLayers"].nodes;
        window.app.map.getView().on('change:resolution', this.mapViewChanged)
        // console.log(this.checked);
    }

    mapViewChanged(){
        if(window.app.floatingPane.state && window.app.floatingPane.state.toolPane && window.app.floatingPane.state.toolPane.style.visibility !== 'hidden' && window.app.floatingPane.state.toolPane.title === this.getTitle()) {
            this.onToolIconClick();
        }
    }
    
   
    
    getTitle(){
        return "Layer List";
    }

    getHeight(){
        return "450px"
    }

    getIconClass(){ 
        return "fas fa-layer-group";
    }

    onNodeClick(node){
        // alert(node.label)
        //window.app.floatingPane.render();
        // this.onToolIconClick();
    }
    onCheckedStateChange(checked){
        this.checked = checked;
        window.app.layerList["opLayers"].checked = checked;
        // console.log(this.checked);    
        var opLayers = window.app.layers["opLayers"]
        for(var i in opLayers){
            var layer = opLayers[i];
            var visible = false;
            if(checked.includes(i)) visible = true;
            if(i.indexOf("g_")<0) this.setLayerVisible(layer, visible);
        }
        // alert(checked)
    }

    setLayerVisible(layer, visible){
        var currVisible = layer.getVisible();
        if((currVisible && visible) || (!currVisible && !visible)) return;
        layer.setVisible(visible);
        var gLayer = window.app.layers.graphicLayers? window.app.layers.graphicLayers[layer.name]:null;
        gLayer = gLayer? gLayer:layer.graphicLayer;
        if(gLayer){
            loadGraphicLayerFeatures(gLayer, visible)
        }
    }

    onExpandedStateChanged(expanded){
        this.expanded = expanded;
    }
    onIconClick(e){
        e.stopPropagation();
    }

    updateNodeIcon(nodes){
        for(var i in nodes){
            var node = nodes[i];
            if(node.children){
                this.updateNodeIcon(node.children);
            }else {
                var layer = window.app.layers["opLayers"][node.value];
                var reso = window.app.map.getView().getResolution();
                var inRange = isLayerVisible(layer, reso);
                if(!inRange) node.icon = <i className="fa fa-eye-slash fa-xs" ></i>;
                else  node.icon = <i className="fa fa-eye fa-xs" ></i>;
            }
        }
    }

    getContent(){
        // const nodes = [
        //     {
        //         value: 'Documents',
        //         label: 'Documents',
        //         children: [
        //             {
        //                 value: 'Expense Report',
        //                 label: 'Expense Report.pdf',
        //                 checked: true,
        //                 icon: <i className="fas fa-circle"></i>,
        //             },
        //             {
        //                 value: 'Employee Evaluations',
        //                 label: 'Employee Evaluations',
        //                 // icon: <i 
        //                 //     className="fas fa-draw-polygon" 
        //                 //     name='Employee Evaluations.zip' 
        //                 //     onClick = {this.onIconClick}
        //                 // ></i>,
                        
        //             },
        //             {
        //                 value: 'Expense Report',
        //                 label: 'Expense Report.pdf',
        //                 icon: <i className="fas fa-circle"></i>,
        //             },
        //             {
        //                 value: 'notes.txt',
        //                 label: 'notes.txt',
        //                 icon: <i className="fas fa-bacon" />,
        //             },
        //         ],
        //     },
        //     {
        //         value: 'Photos',
        //         label: 'Photos',
        //         children: [
        //             {
        //                 value: 'nyan-cat.gif',
        //                 label: 'nyan-cat.gif',
        //                 icon: <i className="fa fa-file-image-o" />,
        //             },
        //             {
        //                 value: 'SpaceX Falcon9 liftoff.jpg',
        //                 label: 'SpaceX Falcon9 liftoff.jpg',
        //                 icon: <i className="fa fa-file-image-o" />,
        //             },
        //         ],
        //     },
        // ];

        this.updateNodeIcon(this.nodes);
// console.log(this.checked);
        return (<div style={{marginLeft:'-8px'}}>
            <TreeView 
                nodes={this.nodes} 
                checkModel = 'all'
                checked={this.checked} 
                expanded={this.expanded}
                onNodeClick={this.onNodeClick}
                checkedStateChanged = {this.onCheckedStateChange}
                expandedStateChanged = {this.onExpandedStateChanged}
                >
            </TreeView>
        </div>)

    }


}

export default Widget;  