
export const headerTools = function(){
    let tools = [];
    var appConfig = window.app.config;
    if(appConfig.showLayerList){
        tools.push({name:"BaseMap", config:appConfig.layers.baseMap, tool:require('./BaseMap/Widget').default})
        if(appConfig.layers.opLayers&&appConfig.layers.opLayers.length>0) {
            tools.push({name:'LayerList', config: appConfig.layers.opLayers, tool:require('./LayerList/Widget').default})
            tools.push({name:'Legend', config: appConfig.layers.opLayers, tool:require('./Legend/Widget').default})
        }
    }

    if(appConfig.FMV) 
        tools.push({name:"FMV", config:appConfig.FMV, tool:require('./FMV/Widget').default})

    if(appConfig.searchTools) 
        tools.push({name:"Search", config:appConfig.searchTools, tool:require('./Search/Widget').default})
    // Tools['Identify'] = require('./Identify/Widget').default;
    return tools;
}
