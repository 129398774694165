import { buildXMLFilter } from './Util'


export function getExtent(geometry) {
  var bbox = require('@turf/bbox').default;
  return bbox(geometry);
}

export function isInOrgBoundary(geom) {
  // console.log(window.app.orgBoundary)
  var booleanDisjoint = require('@turf/boolean-disjoint').default;
  return true;
  // return !booleanDisjoint(window.app.orgBoundary, geom);
}

function buildIntersectsQueryXMLString(layer, properties) {
  var props = layer.get('props');
  var layerName = props.layer;
  var xml = '<wfs:GetFeature service="WFS" version="1.1.0"  xmlns:topp="http://www.openplans.org/topp"  xmlns:wfs="http://www.opengis.net/wfs"  xmlns="http://www.opengis.net/ogc"  xmlns:gml="http://www.opengis.net/gml"  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"  xsi:schemaLocation="http://www.opengis.net/wfs                      http://schemas.opengis.net/wfs/1.1.0/wfs.xsd">';
  xml += '<wfs:Query typeName="' + layerName + '">'
  xml += buildXMLFilter(props, properties);
  xml += '</wfs:Query></wfs:GetFeature> ';
  // console.log(xml)
  return xml;
}

export function zoomTo(geom) {
  window.app.map.getView().fit(geom, { duration: 1000 });
}

export function getLayerFeaturesWithPropertyValue(layer, property, callback) {
  if(property.geometry){
    property.geometry = {
      type: "Polygon",
      coordinates: getGeometryStringFromCoordinate(property.geometry.coordinates[0]),//"-74.817265 40.5296504 -74.812265 40.5296504 -74.812265 40.5326504 -74.817265 40.5296504",//"-74.817265 40.5296504 -74.812265 40.5296504 -74.812265 40.5326504 -74.817265 40.5296504"
      srsId: 3857
    }
  }else if(window.app.orgBoundary)
    property.geometry = {
      type: "Polygon",
      coordinates: getGeometryStringFromCoordinate(window.app.orgBoundary.coordinates[0][0]),//"-74.817265 40.5296504 -74.812265 40.5296504 -74.812265 40.5326504 -74.817265 40.5296504",//"-74.817265 40.5296504 -74.812265 40.5296504 -74.812265 40.5326504 -74.817265 40.5296504"
      srsId: 3857
    }
  var data = buildIntersectsQueryXMLString(layer, property);
  postFeatureByCQL(window.app.config.wfsUrl + '&outputFormat=JSON', data, callback)
}

export function getLayerFeaturesWithinMapExtent(layer, properties, callback) {
  const { polygon } = require('@turf/helpers');
  const intersect = require('@turf/intersect').default
  const bboxPloygon = require('@turf/bbox-polygon').default

  var mapExtent = bboxPloygon(window.app.map.getView().calculateExtent());

  var poly = polygon(window.app.orgBoundary.coordinates[0]);
  var intersection = intersect(poly, mapExtent);
  if (intersection) {
    var geometry = {
      type: "Polygon",
      coordinates: getGeometryStringFromCoordinate(intersection.geometry.coordinates[0]),//"-74.817265 40.5296504 -74.812265 40.5296504 -74.812265 40.5326504 -74.817265 40.5296504",//"-74.817265 40.5296504 -74.812265 40.5296504 -74.812265 40.5326504 -74.817265 40.5296504"
      srsId: 3857
    }
    var data = buildIntersectsQueryXMLString(layer, geometry, properties);
    postFeatureByCQL(window.app.config.wfsUrl + "&outputFormat=JSON", data, callback)
  } else callback([]);
}

export function getFeatureByCQL(params, callback) {
  var url = params.svcUrl + "&typenames=" + params.layerName + "&CQL_FILTER=" + params.cql + "&service=WFS&request=GetFeature&outputFormat=JSON";
  if (params.outSRS) url += "&srsName=" + params.outSRS;
  // url = params.svcUrl+"&request=GetFeature&version=1.1.0&typeName=topp:states&propertyName=STATE_NAME,PERSONS&BBOX=-75.102613,40.212597,-72.361859,41.512517,EPSG:4326"
  // console.log(url)
  // url = "request=GetFeature&version=1.1.0&typeName=topp:states&propertyName=STATE_NAME,PERSONS&BBOX=-75.102613,40.212597,-72.361859,41.512517,EPSG:4326"
  fetch(url).then(function (response) {
    return response.json();
  }).then(function (json) {
    // console.log(json)
    // var features = new GeoJSON().readFeatures(json);
    callback(json);
  });
}

export function postFeatureByCQL(url, params, callback) {
  var data = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: params
  }
  // console.log(params)
  fetch(url, data).then(function (response) {
    return response.json();
  }).then(function (json) {
    // console.log(json)
    callback(json);
  });
}

export function isLayerVisible(layer, resolution) {
  if (!resolution) resolution = window.app.map.getView().getResolution();
  if (layer.maxResolution) {
    var inRange = layer.maxResolution >= resolution;
    if (layer.minResolution) inRange = inRange && layer.minResolution <= resolution;
    return inRange;
  } else {
    var min = layer.getMinResolution();
    var max = layer.getMaxResolution();
    return resolution >= min && resolution <= max;
  }
}

export function setLayerFilter(layer, cqlFilter) {
  var source = layer.getSource();
  var params = source.getParams();
  params.CQL_FILTER = cqlFilter
  source.updateParams(params);
}

function getGeometryStringFromCoordinate(coordinates) {
   console.log(coordinates);
  var geoString = coordinates[0][0] + " " + coordinates[0][1] + " ";
  // console.log(coordinates[1])
  geoString += coordinates[1][0] + " " + coordinates[1][1].toFixed(2) + " ";
  for (var i = 2; i < coordinates.length; i++) {
    geoString += coordinates[i][0] + " " + coordinates[i][1] + " "
  }
  return geoString.substring(0, geoString.length - 1);
}

export function getGeometryStringFromFlatCoordinates(type, flatCoordinates) {
  var geoString = type + "((";
  for (var i = 0; i < flatCoordinates.length / 2; i++) {
    geoString += flatCoordinates[2 * i] + " " + flatCoordinates[2 * i + 1] + ","
  }
  geoString = geoString.substring(0, geoString.length - 1);
  geoString += "))";
  return geoString;
}

export function getFeaturesByPropertyValue(searchProps, searchValue, callback, errback) {
}

function handleWTFeature(feats, searchProps, callback, errback) {
  var w = this;
  var nonGeoFeats = {};
  var searchValues = [];
  for (var i in feats) {
    var feat = feats[i];
    if (feat.geometry) {
      feat.srs = "EPSG:4326";
    } else {
      var value = feat.properties[searchProps.keyName];
      nonGeoFeats[value] = feat;
      searchValues.push(value);
    }
  }

  if(searchValues.length===0){
    callback(feats);
  }else{
    var layer = window.app.layers["opLayers"][searchProps.layer];
    var property = [{
      name: searchProps.layerField,
      values: searchValues,
      isEqual: true
    }]
    getLayerFeaturesWithPropertyValue(layer, property, function (resp) {
      var crs = resp.crs.properties.name;
      var shouldTrans = crs.indexOf("3857") < 0;
      for(var k in resp.features){
        var newFeat = resp.features[k]
        var keyValue = newFeat.properties[searchProps.layerField];
        var feat = nonGeoFeats[keyValue];
        feat.geometry = newFeat.geometry;
        if (shouldTrans) {
          feat.srs = "EPSG:4326"
        }
      }
      callback(feats)
    }, function (err) {
      errback(err);
    });
  }
}

