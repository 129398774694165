import React from 'react';
import { getExtent, getLayerFeaturesWithPropertyValue, zoomTo, getFeaturesByPropertyValue } from "../../MapUtil";
import { addLeadingZeros } from "../../Util";
import FeatureList from '../../FeatureList';
import * as olProj from 'ol/proj';
import FeatureListPane from '../../FeatureListPane/Widget';
import {fromExtent} from 'ol/geom/Polygon';


class SearchPane extends React.Component {
        constructor(props) {
                super(props);
                this.resultPane = new FeatureList();
                this.search = this.search.bind(this);
                this.inputKeyPressed = this.inputKeyPressed.bind(this);
                this.nameSelected = this.nameSelected.bind(this);
                this.defaultValues = {};
                this.state = {
                        features: []
                }
        }

        searchGrid(searchProps, input){
                var w = this;
                var values = input.split("-");
                var layers = searchProps.layers;
                var layerName, value;

                if(values.length>3){
                        layerName = layers[3];
                        if(values[values.length-1].indexOf("W")>0){
                                value = addLeadingZeros(values[values.length-2], 3) + "-" + values[values.length-1].replace("W", "-W");
                        }else value = addLeadingZeros(values[values.length-3], 3) + "-" + addLeadingZeros(values[values.length-2], 2) + "-W" + values[values.length-1];
                }else{
                        alert("Invalid input!");
                        return false;
                }
                var layer = window.app.layers["opLayers"][layerName];
                var callback = function (resp) {
                        var features = resp.features;
                        if (features.length > 0) {
                                var extent = getExtent(features[0].geometry);
                                var crs = resp.crs.properties.name;
                                if (crs.indexOf("3857") < 0)
                                        extent = olProj.transformExtent(extent, olProj.get('EPSG:4326'), olProj.get('EPSG:3857'));
                                if(values.length>3){
                                        if (values.length === 5) {
                                                if (!isNaN(values[0])) {
                                                        layerName = layers[0];
                                                } else {
                                                        layerName = layers[1];
                                                }
                                                value = values[0] + "-" + addLeadingZeros(values[1], 2) + "-" + addLeadingZeros(values[2], 3) + "-" + addLeadingZeros(values[3], 2) + "-W" + values[4];
                                        } else if (values.length === 4) {
                                                if(input.indexOf("W")>0){
                                                        layerName = layers[0];
                                                        value = values[0]/1 + "-" + addLeadingZeros(values[1], 2) + "-" + addLeadingZeros(values[2], 3) + "-" + values[3].replace("W", "-W");
                                                }else{
                                                        layerName = layers[2];
                                                        value = addLeadingZeros(values[0], 2) + "-" + addLeadingZeros(values[1], 3) + "-" + addLeadingZeros(values[2], 2) + "-W" + values[3];
                                                }
                                        }
                                        var layer = window.app.layers["opLayers"][layerName];
                                        var property = [{
                                                name: searchProps.field,
                                                values: [value],
                                                isEqual: true
                                        }]
                                        property.geometry = fromExtent(extent)
                                        property.geometry.coordinates = property.geometry.getCoordinates()
                                        var callback1 = function (resp) {
                                                var features = resp.features;
                                                if (features.length > 0) {
                                                        var extent = getExtent(features[0].geometry);
                                                        console.log(extent)
                                                        var crs = resp.crs.properties.name;
                                                        if (crs.indexOf("3857") < 0)
                                                                extent = olProj.transformExtent(extent, olProj.get('EPSG:4326'), olProj.get('EPSG:3857'));
                                                        zoomTo(extent);
                                                }
                                        }
                                        getLayerFeaturesWithPropertyValue(layer, property, callback1);  
                                }else{
                                        zoomTo(extent);
                                }
                        } else {
                                alert("Invalid input!")
                        }
                        w.setState({searching:false})
                }
                var property = [{
                        name: searchProps.field,
                        values: [value],
                        isEqual: true
                }]
                getLayerFeaturesWithPropertyValue(layer, property, callback);
        }

        search() {
                this.setState({
                        searching: true,
                        features:[]
                });
                
                var w = this;
                var input = document.getElementById(this.props.selectedOption).value;
                var searchProps = window.app.config.searchTools[this.props.selectedOption];
                if (this.props.selectedOption === "STS Grid") {
                        this.searchGrid(searchProps, input)
                } else if (searchProps.svcName) {
                        getFeaturesByPropertyValue(searchProps, input, function(feats){
                                // console.log(feat);
                                w.setState({
                                        searching: false,
                                        features: feats
                                })
                        }, function(err){
                                w.setState({
                                        searching: false,
                                        features: []
                                })
                        })
                }else if(searchProps.layer){
                        window.app.selections.clear();
                        var layer = window.app.layers["opLayers"][searchProps.layer];
                        var callback = function (resp) {
                                // console.log(features)
                                var features = resp.features;
                                if (features.length > 0) {
                                        var trans = (resp.crs && resp.crs.properties.name.indexOf("3857")<0); 
                                        for(var i in features){
                                                var feat = features[i];
                                                if(trans) feat.srs = "EPSG:4326";
                                                feat.properties={
                                                        GDM_UPI:  feat.properties.GDM_UPI,
                                                        Note: "CLick on Pipe for more info"
                                                }
                                                // window.app.selections.select(feat);
                                        }
                                        w.setState({
                                                searching: false,
                                                features: features
                                        })
                                        console.log(resp);
                                } else {
                                        alert("Invalid input!")
                                        w.setState({searching:false})
                                }
                                                        }
                        var property = [{
                                name: searchProps.field,
                                values: [input],
                                isEqual: true
                        }]
                        getLayerFeaturesWithPropertyValue(layer, property, callback);
                }
        }

        componentDidUpdate() {
                var elem = document.getElementById(this.props.selectedOption);
                if (elem) elem.focus();
        }

        inputKeyPressed(evt) {
                if (evt.keyCode === 13) this.search();
                else this.defaultValues[this.props.selectedOption] = document.getElementById(this.props.selectedOption).value
        }

        nameSelected() {
                if(!document.getElementById(this.props.selectedOption)) return;
                var options = document.getElementById(this.props.selectedOption).options
                if(!options) return;
                var name = options[options.selectedIndex].id;
                var features = this.props.nameFeatures[name]
                window.app.selections.clear();
                if (features)
                        features.forEach(function (feat) {
                                window.app.selections.select(feat);
                        })

                // this.setState({
                //         features: features
                // })
        }

        render() {
                this.nameSelected()
                var contentHtml = ("");
                var value = this.defaultValues[this.props.selectedOption];
                // var result = (this.features)? this.resultPane.getResultPane(this.features): "Test"
                if (this.props.selectedOption !== "--Please Select--" && this.props.selectedOption !== "") {
                        var searchProps = window.app.config.searchTools[this.props.selectedOption];
                        if (searchProps.usingDropdown) {
                                var names = this.props.nameFeatures ? Object.keys(this.props.nameFeatures).sort() : [];
                                if (names.length === 0) contentHtml = (<div style={{ margin: 15 }} >No feature is in the map area, please zoom in further or pan to other area</div>)
                                else
                                        contentHtml = (
                                                <div>
                                                        <select id={this.props.selectedOption} onChange={this.nameSelected} style={{ width: "100%", marginTop: 5 }} defaultValue={value ? value : ""}>
                                                                <option>--Please Select a operator--</option>
                                                                {names.map(name => <option id={name} key={name} >{name + " - "+this.props.nameFeatures[name].length}</option>)}
                                                        </select>
                                                </div>
                                        )
                        } else {
                                var searchBtn = this.state.searching? (<div style={{ margin: 15 }} >searching...</div>):(<button style={{ width: 100, margin: 15 }} onClick={this.search}>Search</button>)
                                contentHtml = (
                                        <div>
                                                <input id={this.props.selectedOption} type="text" autoFocus onKeyUp={this.inputKeyPressed} style={{ width: "97%", marginTop: 5 }} defaultValue={value ? value : ""}></input>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        {searchBtn}
                                                </div>
                                        </div>
                                )
                                if (this.props.selectedOption === "STS Grid") {
                                        contentHtml = (
                                                <div>
                                                        {contentHtml}
                                                        <div style={{ fontSize: 10, marginTop:5 }}>* LSD input format LSD-SEC-TWP-RGE-MER (2-3-29-25-3) </div>
                                                        <div style={{ fontSize: 10, marginLeft: 10}}>Or 02-03-029-25W3</div>
                                                        <div style={{ fontSize: 10, marginTop:5 }}>* QSE input format QSE-SEC-TWP-RGE-MER (NE-3-29-25-3) </div>
                                                        <div style={{ fontSize: 10, marginTop:5 }}>* SEC input format SEC-TWP-RGE-MER (3-29-25-3) </div>
                                                        <div style={{ fontSize: 10, marginTop:5 }}>* TWP input format TWP-RGE-MER (29-25-3) </div>
                                                </div>
                                        )
                                }else{
                                        contentHtml = (
                                                <div>
                                                        {contentHtml}
                                                        <FeatureListPane features={this.state.features} zoom={true}></FeatureListPane>
                                                </div>
                                        )
                                }
                        }
                        
                }
                return contentHtml;

        }
}

export default SearchPane;  