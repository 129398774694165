import React from 'react';
import CheckboxTree from 'react-checkbox-tree';


class CustomIconsExamples extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.checked,
            expanded: this.props.expanded,
        };

        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onClick(node){
        this.props.onNodeClick(node)
    }

    onCheck(checked) {
        this.setState({ checked });
        this.props.checkedStateChanged(checked);
    }

    onExpand(expanded) {
        this.setState({ expanded });
        this.props.expandedStateChanged(expanded);
    }

    render() {
        const { checked, expanded } = this.state;

        return (
            <div className="clickable-labels">
            <CheckboxTree
                checked={checked}
                expanded={expanded}
                iconsClass="fa5"
                nodes={this.props.nodes}
                onCheck={this.onCheck}
                onExpand={this.onExpand}
                onClick={this.onClick}
                //showExpandAll={true}
            />
            </div>
        );
    }
}

export default CustomIconsExamples;