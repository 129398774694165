import {getOlFeature} from './OLLayers'
import {isLayerVisible} from './MapUtil.js';
import * as olProj from 'ol/proj';


export function loadGraphicLayerFeatures(gLayer, visible){
        var props = gLayer.get("props");
        if(props.graphicSvc.displayMode>0){
                runPreEvents(gLayer)
                var layer = window.app.layers.opLayers[props.name];
                var isVis = (visible===undefined)?layer.getVisible():visible
                if(isLayerVisible(layer)&&isVis){
                        gLayer.setVisible(true)
                        var mapExtent = window.app.map.getView().calculateExtent();
                        var lonlat0 = olProj.transform([mapExtent[0],mapExtent[1]], 'EPSG:3857', 'EPSG:4326');
                        var lonlat1 = olProj.transform([mapExtent[2],mapExtent[3]], 'EPSG:3857', 'EPSG:4326');
        
                        // var searchString = "token="+window.app.user.token;
                        // searchString += "&rmName="+window.app.user.organization;
                        var searchString = "&featureType="+props.graphicSvc.typeName;
                        searchString += "&minX="+lonlat0[0] + "&minY="+lonlat0[1] + "&maxX="+lonlat1[0] + "&maxY="+lonlat1[1];//"&minX=-108&maxX=-110&minY=49&maxY=54";//
                }else{
                        gLayer.getSource().clear();
                }
        }
}

function addGraphicFeatures(layerName, features, srs){
        var layer = window.app.layers.graphicLayers[layerName];
        // layer.getSource().clear();
        var configLayer = layer.get("props").graphicSvc;
        var licenseNums = ""
        for(var i in features){
                var feat = features[i];
                licenseNums += ",'"+feat.properties[configLayer.fieldName]+"'";
                feat = getOlFeature(feat, srs)
                feat.layerName = layerName;
                layer.getSource().addFeature(feat);
        }
        runPostEvents(layer)        
        var opLayer = window.app.layers["opLayers"][layerName];
        var source =  opLayer.getSource();
        if(source.getParams){
                var params = opLayer.getSource().getParams();
                params.CQL_FILTER = opLayer.cql + " AND " + configLayer.fieldNameInIHS + " NOT IN (" + licenseNums.substring(1) + ")";
                opLayer.getSource().updateParams(params);
        }
                        
}

function runPreEvents(gLayer){
        if(gLayer.preEvents){
                gLayer.preEvents.forEach(function(evt){
                        evt.run();
                })
        }
}

function runPostEvents(gLayer){
        if(gLayer.postEvents){
                gLayer.postEvents.forEach(function(evt){
                        evt.run();
                })
        }
}