import React from 'react';
import Tool from '../Tool';
import ItemList from '../../ItemList/Widget';
import Hybrid from './images/Google Hybrid Map.PNG'
import Roadmap from './images/Google Roadmap Map.PNG'
import Terrain from './images/Google Terrain Map.PNG'

class Widget extends Tool {

    constructor(props){
        super(props);
        this.getContent = this.getContent.bind(this);
        this.onLayerClick = this.onLayerClick.bind(this);
        this.icons = {
            "Google Hybrid Map":Hybrid,
            "Google Roadmap Map":Roadmap,
            "Google Terrain Map":Terrain
        }
    }

    getTitle(){
        return "Basemaps";
    }

    getHeight(){
        return 320;
    }

    getIconClass(){ 
        return "fas fa-map";
    }

    getContent(){
        if(!this.items){
            this.items = [];
            for(var key in window.app.layers.baseMap){
                this.items.push({name: key, icon: this.icons[key]});
            }
        }
        return (
            <ItemList onClick={this.onLayerClick} items={this.items}></ItemList>
        )
    }

    onLayerClick(item){
        var baseMapLayers = window.app.layers.baseMap;
        for(var key in baseMapLayers){
            var layer = baseMapLayers[key];
            if(key===item) layer.setVisible(true);
            else layer.setVisible(false);
        }
        this.close();
    }
}

export default Widget;  