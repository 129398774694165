import React from 'react';
// import {Stroke, Fill, Circle, Style} from 'ol/style';
import './style.css';

class FeatureList {

    constructor(props){
        this.init();
        this.moveNext = this.moveNext.bind(this);
        this.movePrevious = this.movePrevious.bind(this);
        this.removeHighlight=this.removeHighlight.bind(this)
    }

    init(){
        this.features = [];
        this.currentIndex = 0;
        this.layerCount = 0;
        this.processedLayerCount = 0;
    }

    removeHighlight(){
        window.app.highlightLayer.clear();
    }

    highlightFeature(feat){
        window.app.highlightLayer.highlight(feat, this.autoZoom);
    }

    updateFloatingPane(){
        this.removeHighlight();
        var content, title;// = this.buildFeatureTable();
        if(this.features.length===0) {
                if(this.layerCount>this.processedLayerCount) {
                        content = ("loading..");
                }else{
                        content = ("No feature found at the clicked location.");
                } 
                title = "Feature 0/0";
        }else{
                var feat = this.features[this.currentIndex];
                this.highlightFeature(feat);
                // console.log(feat)
                content = this.buildFeatureTable(feat);
                title = "Feature " + (this.currentIndex+1) + "/" + this.features.length;
        }
        var floatingPane = window.app.floatingPane;
        floatingPane.setProperties("resultPane", {
                title: title,
                content: content,
                style:{
                        visibility: 'visible',
                        width: 300,
                        height: 300,
                        left:5,
                        top:110,
                },
                moveToPreviousItem: this.features.length<2?null:this.movePrevious,
                moveToNextItem: this.features.length<2?null:this.moveNext,
                onClose: this.removeHighlight
            })
        }

    openResultPane(feats, autoZoom){
        if(!feats ) return;
        this.autoZoom = autoZoom;
        this.features = this.features.concat(feats);
        this.processedLayerCount += 1;
        this.updateFloatingPane();

    }

    moveNext(){
        this.currentIndex += 1;
        if(this.currentIndex===this.features.length) this.currentIndex = 0;
        this.updateFloatingPane();
    }

    movePrevious(){
        this.currentIndex -= 1;
        if(this.currentIndex===-1) this.currentIndex = this.features.length-1;
        this.updateFloatingPane();
    }

    buildFeatureTable(feat){
            if(!feat.properties){
                feat.properties = feat.values_.properties;   
            }
        return (
                <div key={feat.ol_uid}>
                        <div style={{backgroundColor:"#34d3eb"}}>{feat.id}</div>
                        <table id="feature"><tbody>
                                {
                                        Object.keys(feat.properties).map(key=>{
                                                var keyName = window.app.config.fieldMap[key];
                                                if(!keyName) keyName = key
                                                return (<tr key={key}><th>{keyName}</th><td>{feat.properties[key]}</td></tr>)
                                        })
                                }
                        </tbody></table>
                </div>
        )
                
    }

        getResultPane(features){
                this.features = features;
                this.removeHighlight();
                var feat = this.features[this.currentIndex];
                this.highlightFeature(feat);
                var content = this.buildFeatureTable(feat);
                var title = "Feature " + (this.currentIndex+1) + "/" + this.features.length;
                var moveToPreviousItem = this.features.length<2?null:this.movePrevious;
                var moveToNextItem = this.features.length<2?null:this.moveNext;
                return (
                        <div>
                                <div className="header">
                                        {moveToPreviousItem?(<i onClick={moveToPreviousItem} title={"Previous Item"} className="fa fa-minus headerIcon" aria-hidden="true"></i>):('')}
                                        {moveToNextItem?(<i onClick={moveToNextItem} title={"Next Item"} className="fa fa-plus headerIcon" aria-hidden="true"></i>):('')}
                                        <strong >
                                        {title}
                                        </strong>
                                        {/* <img src={logo} onClick={close} className="closeImg headerIcon" alt='Close'></img> */}
                                </div>
                                <div className="contentPane" style={{height: 300}}>
                                        <div className="content">
                                        {content }
                                        </div>
                                </div>  
                        </div>  
                )            
        }

}

export default FeatureList;  