import React from 'react';
import Tool from '../Tool';
import SearchPane from "./SearchPane";
import {getLayerFeaturesWithinMapExtent, isLayerVisible} from "../../MapUtil";

class Widget extends Tool {
    constructor(props){
        super(props);
        this.searchOptionChanged = this.searchOptionChanged.bind(this);
        this.processFeatures = this.processFeatures.bind(this);
        this.buildDropdownList = this.buildDropdownList.bind(this);
        this.selectedOption = "";
        this.searchOptions = Object.keys(window.app.config.searchTools);
        this.nameFeatures = null
    }

    addMapEvent(){
        // var w = this;
        // var gLayerNames = Object.keys(window.app.layers.graphicLayers)
        // gLayerNames.forEach(function(gLayerName){
        //     var gLayer = window.app.layers.graphicLayers[gLayerName]
        //     if(!gLayer.preEvents) gLayer.preEvents = [];
        //     gLayer.preEvents.push({
        //         widget: w,
        //         run: function(){
        //             this.widget.processFeatures([]);
        //         }
        //     })
        //     if(!gLayer.postEvents) gLayer.postEvents = [];
        //     gLayer.postEvents.push({
        //         widget: w,
        //         run: function(){
        //             this.widget.buildDropdownList();
        //         }
        //     })
        // })
        // window.app.map.on('moveend', this.buildDropdownList);
    }

    buildDropdownList(){
        this.nameFeatures = null;
        var toolPane = window.app && window.app.floatingPane && window.app.floatingPane.property.toolPane;
        if( toolPane && toolPane.style.visibility==="visible" && toolPane.title === this.getTitle()){
            this.selectedOption = document.getElementById("searchOption").value
            var searchProps = window.app.config.searchTools[this.selectedOption];
            if(searchProps&&searchProps.usingDropdown){
                var layer = window.app.layers.opLayers[searchProps.layer];
                if(isLayerVisible(layer)) {
                    this.getFeatures();
                }else{
                    this.processFeatures([]);
                }
                return true;
            }
        }
        return false;
    }

    searchOptionChanged(evt){
        window.app.selections.clear();
        if(!this.buildDropdownList()){
            this.onToolIconClick();
        }
    }

    getFeatures(){
        var w = this;
        var layerName = window.app.config.searchTools[this.selectedOption].layer;
        var layer = window.app.layers.graphicLayers[layerName]
        var features = [];
        if(layer){
            features = layer.getSource().getFeatures();
        }
        var opLayer = window.app.layers["opLayers"][layerName];
        if(opLayer && !opLayer.isGraphicLayer){
            var properties = [
                {
                    name: "LICENSE",
                    values: [],
                    isEqual: false
                }
            ]
            for(var i in features){
                var feat = features[i];
                properties[0].values.push(feat.getProperties().properties["License Number"]);
            }
            getLayerFeaturesWithinMapExtent(opLayer, properties, function(resp){
                var trans = (resp.crs && resp.crs.properties.name.indexOf("3857")<0); 
                if(resp.features) {
                    features = features.concat(resp.features)
                }
                w.processFeatures(features, trans);
            }) 
        }else{
            w.processFeatures(features);
        }

        
    }

    processFeatures(features, trans){
        // console.log(features)
        var searchProps = window.app.config.searchTools[this.selectedOption];
        this.nameFeatures = {}
        for(var i in features){
            var feat = features[i];
            if(trans) feat.srs = "EPSG:4326";
            var properties = feat.properties?feat.properties:feat.getProperties().properties;
            // console.log(properties)
            var name = properties[searchProps.field];
            if(!name) name = properties[searchProps.wt_field];
            var feats = this.nameFeatures[name];
            if(feats) feats.push(feat);
            else this.nameFeatures[name] = [feat];
        }
        this.onToolIconClick();
    }

    getContent(){
        var btnHtml = this.nameFeatures?(<button style={{ width: 200, margin: 15 }} onClick={this.buildDropdownList}>Re-Search in the map area</button>):("");
        return (
            <div>
                <div>
                    <select id="searchOption" onChange={this.searchOptionChanged} style={{width:'100%', textAlignLast:"center"}}>
                        <option>--Please Select--</option>
                        {this.searchOptions.map(searchOption => <option key={searchOption} defaultValue={this.selectedOption===searchOption}>{searchOption}</option>)}
                    </select>
                </div>
                <SearchPane selectedOption={this.selectedOption} nameFeatures={this.nameFeatures}></SearchPane>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {btnHtml}
                </div>
            </div>
        );
    }

    getTitle(){
        return "Search";
    }

    getHeight(){
        return '500px';
    }

    getIconClass(){ 
        return "fas fa-search";
    }

}

export default Widget;  