import React, { Component } from 'react';
import OLMap from './widgets/OLMap'
import Header from './widgets/Header/Widget'
import ResizablePanels from "resizable-panels-react";

class App extends Component {
  constructor(props){
    super(props);
    // console.log(props)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.state = {
      loading: true,
      paneHeight: window.innerHeight-50,
      externalPanes: {}
    };
    window.app.instance = this;
    // window.app.config = require(window.location.origin+"./config_906b353a-14f7-4ed5-9176-2911ce0f5fd0aa.js").config;
    // // window.app.config = c.config
    // console.log(window.app.config)
  }

  updateDimensions(){
    // if(window.app.sideWindow) window.app.sideWindow.onSizeUpdate();
    this.setState({
      externalPaneHeight: window.innerHeight-50
    })  
  }

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions)
    this.setState({
        loading: false,
    })
  }

  resetExternalPanes(direction){
    this.setState({
      externalPanes: this.state.externalPanes
    })
  }

  addExternalPane(direction, panelWidget, index){
    if(!this.state.externalPanes[direction]){
      this.state.externalPanes[direction] = [panelWidget];
    }else{
      if(!index) this.state.externalPanes[direction].push(panelWidget);
      else this.state.externalPanes[direction].splice(index, 0, panelWidget);
    }
    this.resetExternalPanes(direction);
  }

  removeExternalPane(panelWidget){
    var dir = null;
    for(dir in this.state.externalPanes){
      var panes = this.state.externalPanes[dir];
      var idx = panes.indexOf(panelWidget);
      if(idx>=0){
        panes.splice(idx, 1);
        break;
      }
    }
    this.resetExternalPanes(dir);
  }

  buildPanes(panes, type){
    return (
      <ResizablePanels displayDirection={type} width="100%" height={this.state.paneHeight} panelsSize={[50, 50]} sizeUnitMeasure="%" resizerColor="grey" resizerSize="5px">
      {panes[0]}
      {panes[1]}  
    </ResizablePanels>
    )
  }

  render() {
    const {loading} = this.state;
    var headerArea = loading? (<div style={{height:25, textAlign: "center"}}><p>Loading...</p></div>):(
      <Header></Header>)
    var mainArea = (<OLMap></OLMap> );
    if(this.state.externalPanes.bottom&&this.state.externalPanes.bottom.length>0){
      var bottoms = this.state.externalPanes.bottom[0].getContent();
      for(var i = 1; i<this.state.externalPanes.bottom.length; i++){
        bottoms = this.buildPanes([bottoms, this.state.externalPanes.bottom[i].getContent()], "row")
      }
      mainArea = this.buildPanes([mainArea, bottoms], "column")
    }
    if(this.state.externalPanes.left&&this.state.externalPanes.left.length>0){
      var lefts = this.state.externalPanes.left[0].getContent();
      for( i = 1; i<this.state.externalPanes.left.length; i++){
        lefts = this.buildPanes([lefts, this.state.externalPanes.left[i].getContent()], "column")
      }
      mainArea = this.buildPanes([lefts, mainArea], "row")
    }
    return (
        <div>
          {headerArea}
          {mainArea}
        </div>
    );
  }
}

export default App;
