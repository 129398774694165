import React, { Component } from 'react';
// Required to use React portals
import './style.css';
import logo from './close.png';

class ExternalPane extends Component {

  constructor(props){
    super(props);
    this.elemId = this.props.title+"ExternalId";
    this.containerId = this.props.title+"ContainerId";

    this.onclose = this.onclose.bind(this)
;
    if(!window.app.externalPanes) window.app.externalPanes = [];
    window.app.externalPanes.push(this);
    this.state = {}
  }

  componentDidMount(){
    var w = this;
    var container = document.getElementById(this.containerId);
    this.setState({videoFrameHeight: window.innerHeight - container.offsetTop});
    var elem = document.getElementById(this.elemId);
    var offsetTop, offsetWidth = 0;
    setInterval(function() {
      if(elem.offsetTop!==offsetTop||elem.offsetWidth!==offsetWidth)
      {
        offsetTop = elem.offsetTop;
        offsetWidth = elem.offsetWidth;
        var width = window.app.mapObj.state.width;
        var height = window.app.mapObj.state.height
        if(w.props.widget.direction==="left"||w.props.widget.direction==="right"){
          width = elem.offsetWidth==0? window.innerWidth:window.innerWidth-elem.offsetWidth-5;
        }else if(w.props.widget.direction==="bottom"){
          height = elem.offsetTop-50;
        }else if(w.props.widget.direction==="top"){
          height = elem.offsetBottom;
        }
        window.app.mapObj.setState({
          width: width,
          height: height
        })
      }
    },200);
    if(this.props.widget&&this.props.widget.paneDidMount) this.props.widget.paneDidMount();
  }

  onclose(){
    console.log("onclose");
    if(this.props.widget&&this.props.widget.closePane) this.props.widget.closePane();
  }

  render () {
    var title = this.props.title;
    var titleElemId = title+"_titleElemId";
    return (
      <div className="box1"  id={this.elemId}>
          <div className="externalPaneHeader">
            <div id={titleElemId}>{title}</div>
            <img src={logo} className="closeImg" alt='Close' onClick={this.onclose}></img>
          </div>
          <div id={this.containerId} className="externalPaneContentPane" style={{height: this.state.videoFrameHeight}}>
            {this.props.content}
          </div>
      </div>
    );
  }
}

export default ExternalPane;