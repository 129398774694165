import React from 'react';
import './style.css';

class Tool extends React.Component {
    constructor(props){
        super(props);
        this.onToolIconClick = this.onToolIconClick.bind(this);
        this.floatingPaneName = "toolPane";
    }

    close(){
        window.app.floatingPane.close(this.floatingPaneName);
    }

    onToolIconClick(){
        if(this.addMapEvent) this.addMapEvent();
        var floatingPane = window.app.floatingPane;
        floatingPane.setProperties(this.floatingPaneName, {
            title: this.getTitle(),
            content: this.getContent(),
            style:{
                visibility: 'visible',
                width: 300,
                height: this.getHeight()||400,
                right: 5,
                top:5,
            }
        })
    }

    getToolIcon(){
        return '';
    }

    getTitle(){
        return "";
    }

    render(){
        return (
            <i title={this.getTitle()} className={this.getIconClass() + ' ToolIcon'}  onClick={this.onToolIconClick}></i>
        );
    }
}

export default Tool;  