import React from 'react';
import './style.css'

class ItemList extends React.Component {
    render() {
        // var baseMapLayers = window.app.map.layers.baseMap;
        var props = this.props;
        return (
            <ul>
                {props.items.map(function(item) {
                    var classNm = item.icon?"icon":"img";
                    var src = item.icon?item.icon:item.url;
                    var itemClass = props.onClick? "listItem pointer": "listItem";
                    //var click = props.onClick?
                    if(!item.nameFirst)
                        return <div key={item.name} className={itemClass} onClick={function(){if(props.onClick) props.onClick(item.name); else return null }}>{src?(<img src={src} className={classNm} alt="" />):("")}{item.name}</div>;
                    else{
                        return <div key={item.name} style={{marginLeft:-25}}><div>{item.name}</div><div>{src?(<img src={src}  style={{marginLeft:15, marginBottom:10 ,marginTop:5 }} alt="" />):("")}</div></div>;
                    }
                })}
            </ul>
        );
    }
}

export default ItemList;