import Image from 'ol/layer/Image';
import {GeoJSON} from 'ol/format';
import {ImageWMS, TileWMS, XYZ, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Icon,Stroke, Circle, Style, Fill} from 'ol/style';
import Feature from 'ol/Feature';
import {LineString, Point, Polygon} from 'ol/geom';
import {zoomTo} from './MapUtil';
import * as olExtent from 'ol/extent';
import * as olProj from 'ol/proj';
import {joinStringArray} from './Util';
// import geojsonObject from 'process.enve.PUBLIC/data.json'

class OLLayers{
        constructor(){
                this.layers = [];
                this.buildOpGraphicLayer = this.buildOpGraphicLayer.bind(this);
                this.buildLayers();
                this.buildSelections();
                this.buildHighlighter();
        }

        getLayers(){
                return this.layers;
        }

        buildLayers(){
                var layerInfos = window.app.config.layers;
                window.app.layers = {};
                window.app.layerList = {};
                for(var key in layerInfos){
                        var layers = layerInfos[key];
                        window.app.layers[key] = [];
                        window.app.layerList[key] = {
                                checked: [],
                                nodes:[]
                        };
                        this.setLayers(key, layers, window.app.layerList[key].nodes, window.app.layerList[key].checked);
                }
                this.layers = Object.values(window.app.layers["baseMap"]).concat(Object.values(window.app.layers["opLayers"]));
                
        }

        setLayers(key, layers, nodes, checked){
                for(var i in layers){
                    var lyr = layers[i];
                    if(lyr.layers){
                        var children = []
                        this.setLayers(key, lyr.layers, children, checked);
                        nodes.push({
                            value:lyr.name,
                            label:lyr.name,
                            children:children
                        })
                    }else{
                        var layer;
                        if(lyr.type==="ImageWMS"){
                                var exclude = lyr.exclude;
                                var cql = "1=1";
                                if(exclude){
                                        cql = exclude.name + " NOT IN (" + joinStringArray(exclude.values)+")";
                                }
                                layer = new Image({
                                        props: lyr,
                                        source: new ImageWMS({
                                                ratio: 1,
                                                url: lyr.url||window.app.config.serverUrl,
                                                params: {
                                                        "LAYERS": lyr.layer,
                                                        CQL_FILTER: cql
                                                }
                                        }),
                                        minResolution: lyr.minResolution,
                                        maxResolution: lyr.maxResolution,
                                        visible: lyr.visible? true: false
                                });
                                layer.cql = cql;
                        }else if(lyr.type==="TileWMS"){
                                layer = new TileLayer({
                                    source: new TileWMS({
                                        url: lyr.url||window.app.config.serverUrl,
                                        params: {
                                                "LAYERS": lyr.layer,
                                                tiled: true,
                                                'VERSION': '1.1.1'
                                        }
                                    }),
                                    visible: lyr.visible? true: false
                                });
                        }else if(lyr.type==="TileLayer"){
                                layer = new TileLayer({
                                        source: new XYZ({
                                        url: lyr.url,
                                        maxZoom: 20
                                        }),
                                        visible: lyr.visible? true: false
                                });
                        }else if(lyr.type==="GeoJSON"){
                                layer = this.buildGeoJsonLayer(lyr);
                        }
                        
                        window.app.layers[key][lyr.name] = layer;
                        if(lyr.visible) checked.push(lyr.name);
                        nodes.push({
                                value:lyr.name,
                                label:lyr.name
                        })
                    }
                }
        }

        buildGeoJsonLayer(lyrProp){
                var path = lyrProp.url;
                if(!path) path = window.location.href+lyrProp.dataPath;
                var vectorSource = new VectorSource({
                        format: new GeoJSON(),
                        url: path//features: new GeoJSON().readFeatures(geojsonObject),
                });

                var image = new Circle({
                        radius: 5,
                        fill: new Fill({
                                color: 'rgba(255, 255, 0, 1)',
                                }),
                        stroke: new Stroke({color: 'red', width: 1}),
                      });
                var styles = {
                        'Point': new Style({
                        image: image,
                        }),
                        'LineString': new Style({
                        stroke: new Stroke({
                        color: 'green',
                        width: 1,
                        }),
                        }),
                        'MultiLineString': new Style({
                        stroke: new Stroke({
                        color: 'green',
                        width: 1,
                        }),
                        }),
                        'MultiPoint': new Style({
                                image: image,
                        }),
                        'MultiPolygon': new Style({
                                stroke: new Stroke({
                                color: 'yellow',
                                width: 1,
                        }),
                        fill: new Fill({
                        color: 'rgba(255, 255, 0, 0.1)',
                        }),
                        }),
                        'Polygon': new Style({
                        stroke: new Stroke({
                        color: 'blue',
                        lineDash: [4],
                        width: 3,
                        }),
                        fill: new Fill({
                        color: 'rgba(0, 0, 255, 0.1)',
                        }),
                        }),
                        'GeometryCollection': new Style({
                        stroke: new Stroke({
                        color: 'magenta',
                        width: 2,
                        }),
                        fill: new Fill({
                        color: 'magenta',
                        }),
                        image: new Circle({
                        radius: 10,
                        fill: null,
                        stroke: new Stroke({
                        color: 'magenta',
                        }),
                        }),
                        }),
                        'Circle': new Style({
                        stroke: new Stroke({
                        color: 'red',
                        width: 2,
                        }),
                        fill: new Fill({
                        color: 'rgba(255,0,0,0.2)',
                        }),
                        }),
                };
      
                var styleFunction = function (feature) {
                        return styles[feature.getGeometry().getType()];
                      };
                var vectorLayer = new VectorLayer({
                        source: vectorSource,
                        style: styleFunction,
                        maxResolution: lyrProp.maxResolution,
                        props: lyrProp
                });
                vectorLayer.isGraphicLayer = true;
                // var vectorLayer = new VectorLayer({
                //         source: new VectorSource({
                //             format: new GeoJSON(),
                //             url: lyrProp.url,
                //         }),
                //         style: styleFunction,
                //         maxResolution: lyrProp.maxResolution,
                //         props: lyrProp
                //     });
                return vectorLayer;
        }


        buildOpGraphicLayer(lyrProp, iconSrc, iconScale){
                var layerName = lyrProp.name
                var style =  new Style({
                        image: new Icon({
                                opacity: 0.75,
                                src: iconSrc, 
                                scale: iconScale
                        })
                });
                var gLayer = new VectorLayer({
                        source: new VectorSource({
                                features: [],
                                useSpatialIndex: true // optional, might improve performance
                        }),
                        style: style,
                        maxResolution: lyrProp.maxResolution,
                        props: lyrProp
                });

                gLayer.name = layerName;
                gLayer.isGraphicLayer = true;
                // gLayer.props = lyrProp;

                if(!window.app.layers.graphicLayers) window.app.layers.graphicLayers = {};
                window.app.layers.graphicLayers[layerName] = gLayer;
                return gLayer;
        }

        buildHighlighter(){
                window.app.highlightLayer = this.buildGraphicLayer(function(feature) {
                            // console.log(feature)
                        var type = feature.getGeometry().getType();
                        if(type==="Point")
                                return new Style({
                                        image: new Circle({
                                        radius: 6,
                                        // fill: new Fill({color: '#34d3eb'}),
                                        stroke: new Stroke({color: '#34d3eb', width: 3})
                                        })
                                });
                        else if(type==="MultiLineString"||type==="LineString"){
                                return new Style({
                                        stroke: new Stroke({color: '#34d3eb', width: 3})
                                });
                        }else if(type==="Polygon"){
                                return new Style({
                                        stroke: new Stroke({color: '#34d3eb', width: 3})
                                });
                        }
                });
                window.app.highlightLayer.highlight = function(feat, autoZoom){
                        this.getSource().clear();
                        var olFeat =  getOlFeature(feat) ; 
                        if(olFeat){
                                this.getSource().addFeature(olFeat)  
                                if(autoZoom) {
                                        var extent = olFeat.getGeometry().getExtent();
                                        extent = olExtent.buffer(extent, 1000);
                                        console.log(extent);
                                        zoomTo(extent) ; 
                                } 
                        }
                        return  olFeat; 
                }
                window.app.highlightLayer.clear = function(){
                        this.getSource().clear()     
                }
                this.layers.push(window.app.highlightLayer)
        }

        buildSelections(){
                window.app.selections = this.buildGraphicLayer(function(feature) {
                            // console.log(feature)
                        var type = feature.getGeometry().getType();
                        if(type==="Point")
                                return new Style({
                                        image: new Circle({
                                        radius: 4,
                                        fill: new Fill({color: 'red'}),
                                        stroke: new Stroke({color: 'red', width: 3})
                                        })
                                });
                        else if(type==="MultiLineString"||type==="LineString"){
                                return new Style({
                                        stroke: new Stroke({color: 'yellow', width: 3})
                                });
                        }else if(type==="Polygon"){
                                return new Style({
                                        stroke: new Stroke({color: 'yellow', width: 3})
                                });
                        }
                });
                window.app.selections.select = function(feat){
                        var olFeat =  getOlFeature(feat) ; 
                        if(olFeat) this.getSource().addFeature(olFeat)  
                        return  olFeat; 
                }
                window.app.selections.clear = function(){
                        this.getSource().clear()     
                }
                this.layers.push(window.app.selections)
        }

        buildGraphicLayer(styleFunction){
                var layer = new VectorLayer({
                        source: new VectorSource({
                                features: [],
                                useSpatialIndex: false // optional, might improve performance
                        }),
                        style: styleFunction
                });
                return layer;
        }


}
export default OLLayers

export function getOlFeature(feat, srs){
        // console.log(feat);
        var geom = feat.geometry;
        // console.log(geom)
        if(!geom){
                if(feat.properties) return null;
                return feat;
        } 
        if(geom.type==="MultiLineString"){
                geom = new LineString(geom.coordinates[0]);
        }else if(geom.type==="Point"){
                geom = new Point(geom.coordinates);
        }else if(geom.type==="MultiPolygon"){
                geom = new Polygon(geom.coordinates[0]);
        } 
        
        srs = feat.srs?feat.srs:srs;
        if(srs && srs!=='EPSG:3857'){
                geom = geom.transform(olProj.get(srs), olProj.get('EPSG:3857'));
        }  
        // console.log(geom)
        return new Feature({geometry:geom, properties: feat.properties});             
}

