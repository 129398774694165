import React from 'react';
import './style.css';

class FeatureListPane extends React.Component {

        constructor(props){
                super(props);
                this.moveNext = this.moveNext.bind(this);
                this.movePrevious = this.movePrevious.bind(this);
                this.state = {
                        features: this.props.features,
                        currentIndex: 0
                };
        }

        moveNext(){
                var currentIndex = this.state.currentIndex + 1;
                if(currentIndex===this.props.features.length) currentIndex = 0;
                this.setState({
                        currentIndex:  currentIndex
                })
        }

        movePrevious(){
                var currentIndex = this.state.currentIndex - 1;
                if(currentIndex===-1) currentIndex = this.props.features.length-1;
                this.setState({
                        currentIndex:  currentIndex
                })
        }

        buildFeatureTable(feat, note){
                if(!feat.properties){
                        feat.properties = feat.values_.properties;   
                }
                var header = feat.id?feat.id:"";
                header += (note)?" "+note:""
                return (
                        <div key={feat.ol_uid}>
                                <div style={{backgroundColor:"#34d3eb"}}>{header}</div>
                                <table id="feature"><tbody>
                                        {
                                                Object.keys(feat.properties).map(key=>{
                                                        var keyName = window.app.config.fieldMap[key];
                                                        if(!keyName) keyName = key
                                                        return (<tr key={key}><th>{keyName}</th><td>{feat.properties[key]}</td></tr>)
                                                })
                                        }
                                </tbody></table>
                        </div>
                )
                
        }

                  
        render(){
                if(!this.props.features) return ("")
                var length = this.props.features.length;
                if(length===0) return ("");
                if(length<=this.state.currentIndex)
                this.setState({currentIndex : 0})
                var feat = this.props.features[this.state.currentIndex];
                // console.log(this.state.currentIndex)
                // console.log(feat)
                
                var olfeat = window.app.highlightLayer.highlight(feat,this.props.zoom);
                var content = this.buildFeatureTable(feat, olfeat?null:"No Geometry");
                var title = "Feature " + (this.state.currentIndex+1) + "/" + length;

                return (
                    <div style={{marginTop:10}}>
                        <div className="header">
                                { length>1 ? (<i onClick={this.movePrevious} title={"Previous Item"} className="fa fa-minus headerIcon" aria-hidden="true"></i> ):('')}
                                { length>1 ? (<i onClick={this.moveNext} title={"Next Item"} className="fa fa-plus headerIcon" aria-hidden="true"></i> ):('')}
                                <strong >
                                        {title}
                                </strong>
                        {/* <img src={logo} onClick={close} className="closeImg headerIcon" alt='Close'></img> */}
                        </div>
                        <div className="contentPane" style={{height:300}}>
                          <div className="content">
                              {content }
                          </div>
                        </div>
                    </div>
      
                )
        }
}

export default FeatureListPane;  